const createInputField = ({
    name, value
}) => {
    const inputElement = document.createElement('input');
    inputElement.type = 'text';
    inputElement.name = name;
    inputElement.id = name;
    inputElement.setAttribute('data-label', name);
    inputElement.value = value;
    inputElement.style.display = 'none';

    return inputElement;
};

const optimizelySetup = ({
    optimizelyVariationIdInput,
    optimizelyVariationNameInput,
    optimizelyExperimentNameInput,
    optimizelyExperimentIdInput,
    optimizelyCampaignIdInput,
    optimizelyVisitorIdInput
}) => {
    const optimizely = window['optimizely'] || window.parent['optimizely']; // Check parent window if embedded

    if (!optimizely) {
        console.error('Optimizely object not found.');
        return;
    }

    const visitor = optimizely.get('visitor_id');
    const activeExperimentIds = optimizely.get('state').getActiveExperimentIds();

    if (activeExperimentIds && activeExperimentIds.length) {
        const experimentId = activeExperimentIds[activeExperimentIds.length - 1];
        optimizelyExperimentIdInput.value = experimentId || '';

        const experimentStates = optimizely.get('state').getExperimentStates({
            isActive: true
        })[experimentId];

        if (experimentStates) {
            const variationId = experimentStates.variation.id;
            optimizelyVariationIdInput.value = variationId;
            optimizelyVariationNameInput.value = experimentStates.variation.name;
            optimizelyExperimentNameInput.value = experimentStates.experimentName;

            const campaigns = optimizely.get('state').getCampaignStates({
                isActive: true
            });

            for (const key in campaigns) {
                const campaign = campaigns[key];
                if (campaign.variation && campaign.variation.id === variationId) {
                    optimizelyCampaignIdInput.value = campaign.id;
                }
            }
        }
    }

    optimizelyVisitorIdInput.value = visitor['randomId'];
    console.log(optimizelyVisitorIdInput.value);
}


const optimizelyVisitorId = createInputField({
    name: 'optimizelyVisitorId', value: ''
});
const optimizelyVariationId = createInputField({
    name: 'optimizelyVariationId', value: ''
});
const optimizelyExperimentId = createInputField({
    name: 'optimizelyExperimentId', value: ''
});
const optimizelyCampaignId = createInputField({
    name: 'optimizelyCampaignId', value: ''
});
const optimizelyVariationName = createInputField({
    name: 'optimizelyVariationName', value: ''
});
const optimizelyExperimentName = createInputField({
    name: 'optimizelyExperimentName', value: ''
});
const formheader = createInputField({
    name: 'formheader', value: 'Zum kostenlosen Probetragen anmelden'
});

let shadowRoot;
const input = document.createElement('input');
input.type = 'text';
input.name = 'priority';
input.id = 'priority';
input.setAttribute('data-label', 'priority');
input.value = '3K';
input.style = "display:none;"

const qualification = document.createElement('input');
qualification.type = 'text';
qualification.name = 'qualification';
qualification.id = 'qualification';
qualification.setAttribute('data-label', 'qualification');
qualification.value = 0;
qualification.style = "display:none;"

const isQualified = document.createElement('input');
isQualified.type = 'text';
isQualified.name = 'isQualified';
isQualified.id = 'isQualified';
isQualified.setAttribute('data-label', 'isQualified');
isQualified.value = false;
isQualified.style = "display:none;"

window.addEventListener('heyflow-init', (event) => {
shadowRoot =
    (document.querySelector(`[flow-id="${event.detail.flowID}"]`) &&
        document.querySelector(`[flow-id="${event.detail.flowID}"]`)
        .shadowRoot) ||
    document;
    const form = shadowRoot.querySelector(`[id="embedded-test"]`);
    form.appendChild(input);
    form.appendChild(qualification);
    form.appendChild(isQualified);
    form.appendChild(optimizelyVisitorId);
    form.appendChild(optimizelyVariationId);
    form.appendChild(optimizelyExperimentId);
    form.appendChild(optimizelyCampaignId);
    form.appendChild(optimizelyVariationName);
    form.appendChild(optimizelyExperimentName);
    form.appendChild(formheader);
    optimizelySetup({
        optimizelyVariationIdInput: optimizelyVariationId,
        optimizelyExperimentIdInput: optimizelyExperimentId,
        optimizelyCampaignIdInput: optimizelyCampaignId,
        optimizelyVisitorIdInput: optimizelyVisitorId,
        optimizelyVariationNameInput: optimizelyVariationName,
        optimizelyExperimentNameInput: optimizelyExperimentName,
    });
});



window.addEventListener('heyflow-change', (event) => {
    console.log('heyflow-change', event.detail.fieldsSimple);
    if (event.detail.fieldsSimple.hasHearingAid == 'true'){
        switch(event.detail.fieldsSimple.howOldIsHearingAid){
        case 'Mehr als 5 Jahre':
            input.value = '1B';
            qualification.value = 1;
            isQualified.value = true;
            break;
        case 'Weniger als 5 Jahre':
            
            input.value = '3G'
            qualification.value = 1;
            isQualified.value = true;
            break;    
    
        
    }}
    else
    
    if (event.detail.fieldsSimple.hasENTPrescription == 'true'){
                input.value = '1E';
                qualification.value = 1;
                isQualified.value = true;
    }
    else{

    if (event.detail.fieldsSimple.userAge > 49) {
                input.value = '1F';
                qualification.value = 1;
                isQualified.value = true;
    }
    
    
    
    } 
    
    
    
});

window.addEventListener('heyflow-screen-leave', (event) => {
    console.log('heyflow-screen-leave', event.detail.screenName);
    if (event.detail.screenName === 'checkreimbursement' || event.detail.screenName === 'checkreimbursement-private')
    {
        formheader.value = 'Ihre Anmeldung zum kostenlosen Probetragen abschließen';
    }
    if (event.detail.screenName === 'postalcode'){
        formheader.value = 'Bitte haben Sie einen Moment Geduld';
    }
    if (event.detail.screenName === 'intermediate-available'){
        formheader.value = 'Gute Nachrichten,&nbsp;es wurden 2 Filialen für Sie gefunden';
    }
    
    if (event.detail.screenName === 'phonenumber'){
        formheader.value = 'Vielen Dank für Ihre Anfrage';
    }
    
    
});


